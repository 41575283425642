import { UIPointOfSale, AvailableCurrenciesSymbols, UIAllCurrencySymbols } from "@flights/types";

type AvailableCurrencies =
  | "euro"
  | "usd"
  | "sek"
  | "nok"
  | "dkk"
  | "gbp"
  | "aud"
  | "uah"
  | "pln"
  | "chf"
  | "qar"
  | "php"
  | "thb"
  | "aed"
  | "clp"
  | "czk"
  | "huf"
  | "isk"
  | "inr"
  | "idr"
  | "bhd"
  | "kwd"
  | "rsd"
  | "omr"
  | "hkd"
  | "cad"
  | "jpy"
  | "bgn"
  | "mxn"
  | "cop"
  | "nzd"
  | "kzt"
  | "pen"
  | "brl"
  | "myr"
  | "sar"
  | "krw"
  | "sgd";

type CurrencyByPointOfSale = { [key in UIPointOfSale]: AvailableCurrencies };
type CurrencySymbolByPointOfSale = { [key in UIPointOfSale]: AvailableCurrenciesSymbols };

export const currencyByPointOfSale: CurrencyByPointOfSale = {
  de: "euro",
  es: "euro",
  it: "euro",
  nl: "euro",
  ie: "euro",
  at: "euro",
  be: "euro",
  se: "sek",
  no: "nok",
  ro: "euro",
  dk: "dkk",
  fi: "euro",
  us: "usd",
  fr: "euro",
  gb: "gbp",
  au: "aud",
  ua: "uah",
  pl: "pln",
  ch: "chf",
  pt: "euro",
  qa: "qar",
  ph: "php",
  th: "thb",
  ae: "aed",
  cl: "clp",
  cz: "czk",
  hu: "huf",
  is: "isk",
  in: "inr",
  bh: "bhd",
  kw: "kwd",
  rs: "rsd",
  id: "idr",
  om: "omr",
  hk: "hkd",
  lu: "euro",
  ca: "cad",
  jp: "jpy",
  mt: "euro",
  cy: "euro",
  hr: "euro",
  ee: "euro",
  bg: "bgn",
  co: "mxn",
  mx: "cop",
  nz: "nzd",
  gr: "euro",
  kz: "kzt",
  pe: "pen",
  mo: "hkd",
  br: "brl",
  my: "myr",
  sa: "sar",
  kr: "krw",
  vn: "usd",
  sg: "sgd"
};

export const currencySymbolByPointOfSale: CurrencySymbolByPointOfSale = {
  de: "EUR",
  es: "EUR",
  it: "EUR",
  nl: "EUR",
  ie: "EUR",
  at: "EUR",
  be: "EUR",
  se: "SEK",
  no: "NOK",
  ro: "EUR",
  dk: "DKK",
  fi: "EUR",
  us: "USD",
  fr: "EUR",
  gb: "GBP",
  au: "AUD",
  ua: "UAH",
  pl: "PLN",
  ch: "CHF",
  pt: "EUR",
  qa: "QAR",
  ph: "PHP",
  th: "THB",
  ae: "AED",
  cl: "CLP",
  cz: "CZK",
  hu: "HUF",
  is: "ISK",
  in: "INR",
  bh: "BHD",
  kw: "KWD",
  rs: "RSD",
  id: "IDR",
  om: "OMR",
  hk: "HKD",
  lu: "EUR",
  ca: "CAD",
  jp: "JPY",
  mt: "EUR",
  cy: "EUR",
  hr: "EUR",
  ee: "EUR",
  bg: "BGN",
  co: "MXN",
  mx: "COP",
  nz: "NZD",
  gr: "EUR",
  kz: "KZT",
  pe: "PEN",
  mo: "HKD",
  br: "BRL",
  my: "MYR",
  sa: "SAR",
  kr: "KRW",
  vn: "USD",
  sg: "SGD"
};

// This is used in getCurrencyForCountry endpoint for SEO/LP only.
// It includes countries where Flights product is not available. Values are from currency.CountryCurrency table.
export const accommodationsCurrencySymbolByCountry: { [country_code: string]: UIAllCurrencySymbols } = {
  ae: "AED",
  af: "AFN",
  al: "ALL",
  am: "AMD",
  an: "ANG",
  ar: "ARS",
  au: "AUD",
  cc: "AUD",
  ki: "AUD",
  nf: "AUD",
  nr: "AUD",
  sb: "AUD",
  tv: "AUD",
  aw: "AWG",
  az: "AZN",
  ba: "BAM",
  bb: "BBD",
  bd: "BDT",
  bg: "BGN",
  bh: "BHD",
  bm: "BMD",
  bn: "BND",
  bo: "BOB",
  br: "BRL",
  bs: "BSD",
  bt: "BTN",
  bw: "BWP",
  by: "BYN",
  bz: "BZD",
  ca: "CAD",
  ch: "CHF",
  li: "CHF",
  cl: "CLP",
  cn: "CNY",
  co: "COP",
  cr: "CRC",
  cv: "CVE",
  cz: "CZK",
  dk: "DKK",
  fo: "DKK",
  gl: "DKK",
  do: "DOP",
  dz: "DZD",
  eg: "EGP",
  ad: "EUR",
  at: "EUR",
  be: "EUR",
  bl: "EUR",
  cy: "EUR",
  de: "EUR",
  ee: "EUR",
  es: "EUR",
  fi: "EUR",
  fr: "EUR",
  fx: "EUR",
  gf: "EUR",
  gm: "EUR",
  gp: "EUR",
  gr: "EUR",
  gw: "EUR",
  hr: "EUR",
  ie: "EUR",
  it: "EUR",
  km: "EUR",
  lt: "EUR",
  lu: "EUR",
  lv: "EUR",
  mc: "EUR",
  me: "EUR",
  mf: "EUR",
  mg: "EUR",
  mq: "EUR",
  mt: "EUR",
  nl: "EUR",
  pm: "EUR",
  pt: "EUR",
  re: "EUR",
  si: "EUR",
  sk: "EUR",
  sm: "EUR",
  xk: "EUR",
  xy: "EUR",
  yt: "EUR",
  fj: "FJD",
  fk: "GBP",
  gb: "GBP",
  gi: "GBP",
  im: "GBP",
  sh: "GBP",
  uk: "GBP",
  vg: "GBP",
  ge: "GEL",
  gh: "GHS",
  gt: "GTQ",
  gn: "GNF",
  gy: "GYD",
  ht: "HTG",
  hn: "HNL",
  hk: "HKD",
  hu: "HUF",
  is: "ISK",
  in: "INR",
  id: "IDR",
  ir: "IRR",
  iq: "IQD",
  il: "ILS",
  jm: "JMD",
  jp: "JPY",
  jo: "JOD",
  kz: "KZT",
  ke: "KES",
  kp: "KPW",
  kr: "KRW",
  kw: "KWD",
  kg: "KGS",
  la: "LAK",
  lb: "LBP",
  ls: "LSL",
  lr: "LRD",
  ly: "LYD",
  mo: "MOP",
  mk: "MKD",
  mw: "MWK",
  my: "MYR",
  mv: "MVR",
  ml: "XOF",
  mx: "MXN",
  md: "MDL",
  mn: "MNT",
  ma: "MAD",
  mz: "MZN",
  mm: "MMK",
  na: "NAD",
  np: "NPR",
  nz: "NZD",
  ni: "NIO",
  ne: "XOF",
  ng: "NGN",
  no: "NOK",
  om: "OMR",
  pk: "PKR",
  pa: "PAB",
  pg: "PGK",
  py: "PYG",
  pe: "PEN",
  ph: "PHP",
  pl: "PLN",
  qa: "QAR",
  ro: "RON",
  ru: "RUB",
  rw: "RWF",
  ws: "WST",
  st: "STN",
  sa: "SAR",
  sn: "XOF",
  rs: "RSD",
  sc: "SCR",
  sl: "SLL",
  sg: "SGD",
  so: "SOS",
  za: "ZAR",
  ss: "SSP",
  lk: "LKR",
  sd: "SDG",
  sr: "SRD",
  sz: "SZL",
  se: "SEK",
  sy: "SYP",
  tw: "TWD",
  tj: "TJS",
  tz: "TZS",
  th: "THB",
  tg: "XOF",
  to: "TOP",
  tt: "TTD",
  tn: "TND",
  tr: "TRY",
  tm: "TMT",
  ug: "UGX",
  ua: "UAH",
  us: "USD",
  uy: "UYU",
  uz: "UZS",
  vu: "VUV",
  ve: "VES",
  vn: "VND",
  ye: "YER",
  zm: "ZMW",
  zw: "ZWL"
};
