/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { useHistory } from "react-router-dom";

import useUserAgent from "hooks/useUserAgent";
import useCreateUrl from "hooks/useCreateUrl";
import { useCheckInData } from "hooks/useCheckInData";

import { WebCheckinInnerDesktop } from "./WebCheckinInner.desktop";
import { WebCheckinInner as WebCheckinInnerMobile } from "./WebCheckinInner";

import { WebCheckinInnerDesktopV2 } from "./WebCheckinInnerV2.desktop";
import { WebCheckinInnerV2 as WebCheckinInnerMobileV2 } from "./WebCheckinInnerV2";

import { useStore } from "../../../store";
import { assert } from "utils/assert";

// https://experiment.booking.com/experiments/1735460?message=new_draft
export const WebCheckinInner: React.FC = () => {
  const { isMobile } = useUserAgent();
  const history = useHistory();
  const { createUrl } = useCreateUrl();
  const {
    order: { order }
  } = useStore();

  assert(order);

  const { filteredLegs, segment, segmentIndex } = useCheckInData(order);

  if (filteredLegs.length === 0 || !segment || segmentIndex === undefined) {
    history.push(createUrl(`/booking/order-details/${order.orderToken}`));
    return null;
  }

  return isMobile ? (
    <WebCheckinInnerMobile
      order={order}
      segmentIndex={segmentIndex}
      segment={segment}
      filteredLegsWithOpenedCheckIn={filteredLegs}
    />
  ) : (
    <WebCheckinInnerDesktop
      order={order}
      segmentIndex={segmentIndex}
      segment={segment}
      filteredLegsWithOpenedCheckIn={filteredLegs}
    />
  );
};

type WebCheckinInnerV2Props = {
  segmentIndex: number;
};

export const WebCheckinInnerV2 = ({ segmentIndex }: WebCheckinInnerV2Props) => {
  const { isMobile } = useUserAgent();
  const history = useHistory();
  const { createUrl } = useCreateUrl();
  const {
    order: { order }
  } = useStore();

  assert(order);

  const segment = order.airOrder.flightSegments[segmentIndex];
  const legs = order.airOrder.flightSegments[segmentIndex].legs;

  if (!order.carrierCheckinInfo) {
    history.push(createUrl(`/booking/order-details/${order.orderToken}`));
    return null;
  }

  return isMobile ? (
    <WebCheckinInnerMobileV2
      order={order}
      segmentIndex={segmentIndex}
      segment={segment}
      legs={legs}
      checkinInfoForLeg={order.carrierCheckinInfo[segmentIndex]}
    />
  ) : (
    <WebCheckinInnerDesktopV2
      order={order}
      segmentIndex={segmentIndex}
      segment={segment}
      legs={legs}
      checkinInfoForLeg={order.carrierCheckinInfo[segmentIndex]}
    />
  );
};
