/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { useHistory } from "react-router-dom";

import { Trans, useI18n } from "@bookingcom/lingojs-react";
import { Button, Card, Icon, Link, SheetContainer, Stack, Text, Title } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { InfoSignIcon, PopoutIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { useFormatDateTime } from "@bookingcom/flights-core/hooks";

import { UICarrierCheckinInfo, UIFlightSegment, UILeg } from "@flights/types";

import { useStore } from "../../../store";
import { getPassengerFullNameByReference } from "../../../store/selectors/passengers";

import useUserAgent from "../../../hooks/useUserAgent";
import useCreateUrl from "../../../hooks/useCreateUrl";
import useGlobalContext from "../../../hooks/useGlobalContext";
import useEventTracking from "../../../hooks/useEventTracking";

import { CheckinInfoCardItem } from "./CheckinInfoCardItem";

import { SPACER_PADDED } from "../../../constants";

import HintAlert from "../../atoms/HintAlert";

import styles from "./CheckinInfoCard.module.css";

interface CheckInInfoCardProps {
  openCheckin?: boolean;
  checkInInfo?: UICarrierCheckinInfo;
  segment: UIFlightSegment;
  segmentIndex: number;
  leg: UILeg;
  legIndex: number;
  showHeader?: boolean;
  showVuelingAlert?: boolean;
  showSkipAllButton?: boolean;
  isCheckinExperiment?: boolean;
}

export const CheckInInfoCard: React.FC<CheckInInfoCardProps> = ({
  openCheckin,
  checkInInfo,
  leg,
  legIndex,
  showHeader,
  showVuelingAlert,
  segment,
  segmentIndex,
  showSkipAllButton,
  isCheckinExperiment
}) => {
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);
  const store = useStore();
  const { isMobile } = useUserAgent();
  const { createUrl } = useCreateUrl();
  const history = useHistory();
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("pb_webcheckin", requestId);
  const { order } = store.order;

  const [showEmailInfo, setShowEmailInfo] = React.useState(false);

  if (!order) {
    return null;
  }

  const { airOrder, booker } = order;
  const { airTicketsByLeg = [], flightSegments } = airOrder;

  // Finding flight reference by segment and leg index
  const flightReference = airOrder.airlineReferencesByLeg?.find((reference) => {
    return reference.legIdentifier.find((identifier) => {
      return identifier.segmentIndex === segmentIndex && identifier.legIndex === legIndex;
    });
  });
  // 1. Booking reference
  const reference = flightReference?.reference;
  // 2. Flight number
  const flightNumber = leg.carriersData[0].code + leg.flightInfo.flightNumber;
  // 3. Check in email
  const checkinEmail = flightReference?.checkinEmail;

  // 4. Ticket numbers
  const airtickets = airTicketsByLeg.filter((airticket) =>
    airticket.legIdentifiers.find(
      (legIdentifier) => legIdentifier.segmentIndex === segmentIndex && legIdentifier.legIndex === legIndex
    )
  );
  //5. Booker Email Address
  const bookerEmailAddress = booker?.email;

  const isVueling = leg.carriers.some((carrier) => carrier.toLowerCase() === "vy");

  const handleCheckinClick = () => {
    trackV2("click_checkin", {
      order_id: order.orderId,
      segment: segmentIndex,
      legs: [...flightSegments[segmentIndex].legs.keys()]
    });
  };

  const handleSkipClick = () => {
    trackV2("click_skip_checkin", {
      order_id: order.orderId,
      segment: segmentIndex,
      legs: [...flightSegments[segmentIndex].legs.keys()]
    });
    history.push(createUrl(`/booking/order-details/${order?.orderToken}`));
  };

  const checkInLink = checkInInfo?.checkinLink;

  let destinationLeg = leg;
  let i = legIndex + 1;
  // Finds what is the destination within the checkin window.
  // Two examples on how this functionality works for the first leg (legIndex = 0)
  // Example 1: legs: [{ departure: Amsterdam, arrival: London }, { departure: London, arrival: Lisbon }, { departure: Lisbon, arrival: Berlin }]
  // In this case all legs are sharing same check in so the departure would be Amsterdam and arrival would be Berlin
  // Example 2: legs: [{ departure: Amsterdam, arrival: London }, { departure: London, arrival: Lisbon vi: true }, { departure: Lisbon, arrival: Berlin }]
  // In this case route is the same, but the second leg has VI, hence you have to check in for the third leg separately
  // so departure would be Amsterdam and arrival would be Lisbon
  while (segment.legs[i]) {
    destinationLeg = segment.legs[i];
    if (destinationLeg.vi?.protected) {
      break;
    }
    i++;
  }

  return (
    <>
      {showHeader && (
        <Stack gap={0}>
          <Title
            variant="strong_1"
            title={i18n.trans(
              t("flights_pb_checkin_vi_route_subtitle", {
                variables: {
                  origin_city: leg.departureAirport.cityName || "",
                  destination_city: leg.arrivalAirport.cityName || ""
                }
              })
            )}
          />
          <Text variant="body_2" color="neutral_alt">
            {i18n.trans(
              t("flights_pb_checkin_route_itinerary", {
                variables: {
                  airline_name: leg.carriersData[0].name,
                  departure_date: [
                    formats.flightDateWeekday(leg.departureTime),
                    formats.flightTime(leg.departureTime)
                  ].join(SPACER_PADDED)
                }
              })
            )}
          </Text>
        </Stack>
      )}
      {isVueling && showVuelingAlert && (
        <HintAlert iconSize="small" iconColor="neutral" text={i18n.trans(t("flights_cabin_checked_disclaimer"))} />
      )}
      <Card>
        <Stack gap={4}>
          {reference && (
            <Stack direction="row" gap={2}>
              <Stack.Item shrink>
                <Text className={styles.text} variant="body_2">
                  {i18n.trans(t("flights_pb_checkin_booking_reference"))}
                </Text>
              </Stack.Item>
              <Stack.Item shrink>
                <CheckinInfoCardItem
                  text={reference}
                  arialLabel={i18n.trans(t("a11y_flights_pb_checkin_copy_booking_reference"))}
                  onCopySuccessText={i18n.trans(t("flights_pb_checkin_copy_success_booking_reference"))}
                />
              </Stack.Item>
            </Stack>
          )}

          {flightNumber && (
            <Stack direction="row" gap={2}>
              <Text className={styles.text} variant="body_2">
                {i18n.trans(t("flights_pb_checkin_flight_number"))}
              </Text>
              <Stack.Item shrink>
                <CheckinInfoCardItem
                  text={flightNumber}
                  arialLabel={i18n.trans(t("a11y_flights_pb_checkin_copy_flight_number"))}
                  onCopySuccessText={i18n.trans(t("flights_pb_checkin_copy_success_flight_number"))}
                />
              </Stack.Item>
            </Stack>
          )}

          {checkinEmail && (
            <Stack gap={2}>
              <Stack direction="row" gap={2}>
                <Text className={styles.text} variant="body_2">
                  {i18n.trans(t("flights_pb_checkin_email"))}
                </Text>
                <Stack.Item shrink>
                  <CheckinInfoCardItem
                    text={checkinEmail}
                    arialLabel={i18n.trans(t("a11y_flights_pb_checkin_copy_email"))}
                    onCopySuccessText={i18n.trans(t("flights_pb_checkin_copy_success_email"))}
                  />
                </Stack.Item>
              </Stack>
              <Link text={i18n.trans(t("flights_pb_checkin_email_question"))} onClick={() => setShowEmailInfo(true)} />
              <SheetContainer
                active={showEmailInfo}
                position={isMobile ? "bottom" : "center"}
                onClose={() => setShowEmailInfo(false)}
                onCloseTrigger={() => setShowEmailInfo(false)}
                closeAriaLabel={i18n.trans(t("a11y_flights_pb_checkin_email_overlay_close"))}
                title={i18n.trans(t("flights_pb_checkin_email_question"))}
              >
                <Stack gap={2}>
                  <Stack.Item>
                    <Trans
                      tag={"flights_pb_checkin_email_overlay_body"}
                      variables={{ contact_email: bookerEmailAddress, start_bold: "<0>", end_bold: "</0>" }}
                      components={[<strong key={0} />]}
                    />
                  </Stack.Item>

                  <Stack.Item>
                    <Button
                      attributes={{ "aria-label": i18n.trans(t("a11y_flights_pb_checkin_email_overlay_cta")) }}
                      wide={isMobile}
                      size={isMobile ? "large" : "medium"}
                      onClick={() => setShowEmailInfo(false)}
                    >
                      {i18n.trans(t("flights_pb_checkin_email_overlay_cta"))}
                    </Button>
                  </Stack.Item>
                </Stack>
              </SheetContainer>
            </Stack>
          )}

          {airtickets?.length > 0 &&
            airtickets.map((airticket, index) => {
              if (!airticket.ticketNumber) {
                return null;
              }

              const fullName = getPassengerFullNameByReference(i18n, airticket.travelerReference, order?.passengers);

              return (
                <Stack key={index} direction="row" gap={2}>
                  <Text className={styles.text} variant="body_2">
                    {i18n.trans(
                      t("flights_pb_checkin_eticket_number", {
                        variables: {
                          traveller_full_name: fullName
                        }
                      })
                    )}
                  </Text>
                  <Stack.Item shrink>
                    <CheckinInfoCardItem
                      text={airticket.ticketNumber}
                      arialLabel={i18n.trans(
                        t("a11y_flights_pb_checkin_copy_eticket_number", {
                          variables: {
                            traveller_full_name: fullName
                          }
                        })
                      )}
                      onCopySuccessText={i18n.trans(
                        t("flights_pb_checkin_copy_success_eticket_number", {
                          variables: {
                            traveller_full_name: fullName
                          }
                        })
                      )}
                    />
                  </Stack.Item>
                </Stack>
              );
            })}
        </Stack>
      </Card>
      {!checkInLink && (
        <>
          {!openCheckin && (
            <Stack alignItems="center" direction="row">
              <Icon svg={InfoSignIcon} />
              <Text variant="body_2">
                {isCheckinExperiment ? (
                  <Trans
                    tag="flights_pb_bookingdetails_precheckin_time_airline_website_note"
                    variables={{ airline_name: leg.carriersData[0].name, start_bold: "<0>", end_bold: "</0>" }}
                    components={[<strong key={0} />]}
                  />
                ) : (
                  <Trans
                    tag="flights_pb_bookingdetails_precheckin_airline_website_note"
                    variables={{ airline_name: leg.carriersData[0].name, start_bold: "<0>", end_bold: "</0>" }}
                    components={[<strong key={0} />]}
                  />
                )}
              </Text>
            </Stack>
          )}
          {openCheckin && (
            <Stack alignItems="center" direction="row">
              <Icon svg={InfoSignIcon} />
              <Text variant="body_2">
                {isCheckinExperiment ? (
                  <Trans
                    tag="flights_pb_bookingdetails_checkin_boarding_pass_airline_website_note"
                    variables={{ airline_name: leg.carriersData[0].name, start_bold: "<0>", end_bold: "</0>" }}
                    components={[<strong key={0} />]}
                  />
                ) : (
                  <Trans
                    tag="flights_pb_bookingdetails_checkin_airline_website_note"
                    variables={{ airline_name: leg.carriersData[0].name, start_bold: "<0>", end_bold: "</0>" }}
                    components={[<strong key={0} />]}
                  />
                )}
              </Text>
            </Stack>
          )}
        </>
      )}
      {checkInLink && (
        <Stack direction={isMobile ? "column-reverse" : "row"} justifyContent="end">
          {/* if "Skip all" button is shown, don't show individual skip button */}{" "}
          {openCheckin && !showSkipAllButton && (
            <Button
              size="large"
              variant="tertiary"
              attributes={{ "aria-label": i18n.trans(t("a11y_flights_pb_checkin_skip_cta")) }}
              onClick={handleSkipClick}
            >
              {i18n.trans(t("flights_pb_checkin_skip_cta"))}
            </Button>
          )}
          {!openCheckin && (
            <Button
              size="large"
              variant="secondary"
              href={checkInLink}
              attributes={{
                target: "_blank",
                "aria-label": i18n.trans(t("a11y_flights_pb_precheckin_airline_website_cta"))
              }}
              iconPosition="end"
              icon={<Icon svg={<PopoutIcon />} />}
              onClick={handleCheckinClick}
            >
              {i18n.trans(t("flights_pb_precheckin_airline_website_cta"))}
            </Button>
          )}
          {openCheckin && (
            <Button
              size="large"
              variant="primary"
              href={checkInLink}
              attributes={{
                target: "_blank",
                "aria-label": i18n.trans(t("a11y_flights_pb_checkin_airline_website_cta"))
              }}
              iconPosition="end"
              icon={<Icon svg={<PopoutIcon />} />}
              onClick={handleCheckinClick}
            >
              {i18n.trans(t("flights_pb_checkin_airline_website_cta"))}
            </Button>
          )}
        </Stack>
      )}
    </>
  );
};
